// IMPORTS
@use '@angular/material' as mat;

// The core mixin - includes prerequisites styles for common features
@include mat.core();


// Definition of palettes
// LIGHT
$light-primary: mat.define-palette(mat.$deep-orange-palette);
$light-accent: mat.define-palette(mat.$grey-palette);
$light-warn: mat.define-palette(mat.$red-palette);
// DARK
$dark-primary: mat.define-palette(mat.$deep-orange-palette);
$dark-accent: mat.define-palette(mat.$grey-palette);
$dark-warn: mat.define-palette(mat.$red-palette);

// Definition of themes
// LIGHT
$light-theme: mat.define-light-theme((
  color: (
    primary: $light-primary,
    accent: $light-accent,
    warn: $light-warn,
  )
));
// DARK
$dark-theme: mat.define-dark-theme((
  color: (
    primary: $dark-primary,
    accent: $dark-accent,
    warn: $dark-warn,
  )
));

// Applies the theme to components
:root{
    @include mat.all-component-themes($dark-theme);
}

.light-theme {
    @include mat.all-component-colors($light-theme);
}
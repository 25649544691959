@use '@angular/material' as mat;
@import 'libs/angular/ui/src/lib/main-theme.scss';

$color-config: mat.get-color-config($dark-theme);
$primary-palette: map-get($color-config, 'primary');

.card-avatar{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: mat.get-color-from-palette($primary-palette);;
}

.nav-spacer{
  flex: 1 1 auto;
}

table{
  width: 100%;
}

.breadcrumbs{
  display: flex;
  align-items: top;
}

body{
  height: 100vh;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #ff562277;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;

}

::-webkit-scrollbar-thumb:hover {
  background-color: #ff5722;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card {
  margin: 0.5rem 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card-actions {
  display: flex;
  flex-flow: row wrap;
}

.mat-icon.mat-icon-inline {
  line-height: 1!important;
}

.mat-mdc-button {
  margin: 0 0.5rem;
}
